<template>
  <div class="page-home page">
     <MobileCarousel  ></MobileCarousel>
      <div class="products">
        <div class="title">
          <h2 >产品操作可视化</h2>
          <h2 >一键订舱</h2>
        </div>
        <div class="card-list">
           <CardItem v-for="(item, index) in cardlist" :title="item.title" :desc="item.desc" :logo="item.logo" :key="index" > </CardItem>
        </div>
      </div>
      <div class="why-us">
        <h2 class="title">为什么选择恒链</h2>
        <div class="why-us-list">
          <div class="why-us-item">
            <img src="../../../public/static/img/mobile/why_us_01.png" alt="">
            <h4>IPA全流程自动化</h4>
            <p>人工智能和自动化</p>
            <p>助力企业</p>
          </div>
          <div class="why-us-item">
            <img src="../../../public/static/img/mobile/why_us_02.png" alt="">
            <h4>节省人力成本70%</h4>
            <p>复杂操作简单化</p>
            <p>避免重复劳动</p>
          </div>
          <div class="why-us-item">
            <img src="../../../public/static/img/mobile/why_us_03.png" alt="">
            <h4>数据错误率为0</h4>
            <p>业务资料资料分类存储 </p>
            <p>全流程操作可视化</p>
          </div>
          <div class="why-us-item">
            <img src="../../../public/static/img/mobile/why_us_04.png" alt="">
            <h4>提升工作效率</h4>
            <p>一键订舱，一触即达</p>
            <p>操作简单高效</p>
          </div>
        </div>
      </div>
      <div class="client-say">
        <img class="bg" src="../../../public/static/img/mobile/client_bg.png" alt="">
        <h2 class="title">客户这么说</h2>
          <swiper ref="mySwiper" class="my-swiper"  :options="swiperOptions">
            <swiper-slide class="my-wiper-item">
                <div 
                  class="swiper-item-inner"
                
                  >
                  <img :src="img1" alt="">
                  <div class="top">
                    <span>上海泛远</span>
                    <span>黎女士</span>
                  </div>
                  <div class="text">
                    <p>非常方便，每天都在使用，</p>
                    <p>极大的提升了工作效率，</p>
                    <p>系统太棒了</p>
                  </div>
                </div>
            </swiper-slide>
            <swiper-slide class="my-wiper-item" >
              <div 
                class="swiper-item-inner" 
              >
                <img :src="img3" alt="">
                <div class="top">
                  <span>深圳鹏程</span>
                  <span>王先生</span>
                </div>
                <div class="text">
                  <p>操作可视化，系统自动跟踪</p>
                  <p>和提醒每票单子状态，非常</p>
                  <p>的安心，省去很多时间</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="my-wiper-item">
              <div 
                class="swiper-item-inner"
                
                >
                  <img :src="img3" alt="">
                  <div class="top">
                    <span>航嘉货运</span>
                    <span>陈先生</span>
                  </div>
                  <div class="text">
                    <p>一键订舱，自动对接船公</p>
                    <p>司API接口，简化操作的</p>
                    <p>工作模式，非常好</p>
                  </div>
                </div>
              </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
      </div>

      <div class="bg-line"></div>
  </div>
</template>

<script>
import MobileCarousel from "../../components/MobileCarousel";
import CardItem from "./component/CardItem";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import cardIcon1 from "../../../public/static/img/card/icon_01.png";
import cardIcon2 from "../../../public/static/img/card/icon_02.png";
import cardIcon3 from "../../../public/static/img/card/icon_03.png";
import cardIcon4 from "../../../public/static/img/card/icon_04.png";
import cardIcon5 from "../../../public/static/img/card/icon_05.png";

import img1 from "../../../public/static/img/mobile/client_w_a.png";
import img2 from "../../../public/static/img/mobile/client_w.png";
import img3 from "../../../public/static/img/mobile/client_m_a.png";
import img4 from "../../../public/static/img/mobile/client_m.png";

export default {
  name: "app",
  components: {
    MobileCarousel,
    CardItem,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      activeProIndex: 0,
      cardlist: [
        {
          logo: cardIcon1,
          title: '报价查询',
          desc: '实时查询海量船司船代30天最低报价，支持最快，直达等多条件查询'
        },
        {
          logo: cardIcon2,
          title: '在线订舱',
          desc: '无需专业培训和重复数据输入，统一界面，轻松对接所有船司，实时订舱'
        },
        {
          logo: cardIcon3,
          title: '舱单发送',
          desc: '智能加人工双重审核保证，无需二次确认，支持海量船司多家船代'
        },
        {
          logo: cardIcon4,
          title: '票证防伪',
          desc: '量子云码，破坏率90%依然准确识别，完美超越普通二维码'
        },
        {
          logo: cardIcon5,
          title: '智能客服',
          desc: '机器人智能回复用户咨询问题，人工客服与机器人之间轻松切换，相辅相成更高效'
        }
      ],
      img1,
      img2,
      img3,
      img4,
      touchX_start: 0,
      touchX_end: 0,
      swiperOptions: {
        spaceBetween: 300,
          centeredSlides: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: true
          },
        pagination: {
          el: '.swiper-pagination'
        },
      }
    };
  },      
  methods: {
    onSelectedProChange(i) {
      this.activeProIndex = i;
    },
  }
};
</script>

<style lang="scss" scoped>
.page-home {
  .products {
    overflow: hidden;
    margin-bottom: 20px;
    background: linear-gradient(#fff, #fff8f3);
    padding: 36px 0 30px;
    .title {
      margin-bottom: 30px;
      color: #ef7609;
      h2 {
        font-size: 28px;
        text-align: center;
        font-weight: normal;
      }
    }
    .card-list {
      padding: 0 18px;
    }
  }
  .why-us {
    margin-bottom: 30px;
    padding-top: 20px;
    .title {
      font-size: 28px;
      text-align: center;
      font-weight: normal;
      margin-bottom: 20px;
      color: #ef7609;
    }
    .why-us-list {
      display: flex;
      flex-wrap: wrap;
      .why-us-item {
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
        h4 {
          font-size: 18px;
          margin-bottom: 10px;
          color: #5e2905;
          font-weight: 600;
        }
        img {
          width: 80px;
          margin-bottom: 10px;
        }
        p {
          line-height: 22px;
          color: #823c0d;
        }
      }
    }
  }
  .bg-line {
    border: 1px solid #d8d0ce;
    margin: 0px 20px 30px;
  }
  .client-say {
    position: relative;
    z-index: 0;
    .bg {
      width: 100%;
    }
    .title {
      font-size: 28px;
      text-align: center;
      font-weight: normal;
      margin-bottom: 20px;
      color: #ef7609;
      position: absolute;
      top: 12%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .client-item {
    position: absolute;
    width: 80%;
    color: #5e2905;
    transition: all 0.5s;
    &.musk {
      left: 50%;
      top: 35%;
      transform: translateX(-50%);
      font-size: 16px;
      z-index: 3;
      transition: all 0.5s;
    }
    &.musk1 {
      width: 70%;
      left: 0px;
      top: 38%;
      font-size: 14px;
      z-index: 2;
      transition: all 0.5s;
    }

    &.musk2 {
      width: 70%;
      right: 0px;
      top: 38%;
      font-size: 14px;
      z-index: 2;
      transition: all 0.5s;
    }

    img {
      width: 100%;
    }
    .top {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 16%;
      text-align: center;
      span {
        margin-right: 14px;
      }
    }
    .text {
      position: absolute;
      bottom: 18%;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
      margin-left: 2%;
      text-align: center;
    }
  }
  .my-swiper {
    position: absolute;
    width: 100%;
    //覆盖默认的transition动画时长
    /deep/ .swiper-wrapper {
       transition-duration: 1200ms!important;
    }
    .swiper-pagination {
      /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #ebb624;
      }
    }

    .my-wiper-item {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 300px;
      height: 240px;
      text-align: center;
      transition: all 1s;
    }
    .swiper-item-inner {
      max-width: 320px;
      position: relative;
      img {
        width: 100%;
      }
      .top {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 16%;
        text-align: center;
        span {
          margin-right: 14px;
        }
      }
      .text {
        position: absolute;
        bottom: 18%;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        margin-left: 2%;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 415px) {
  .page-home {
    .client-say {
      .my-swiper {
        top: 140px;
        .swiper-item-inner {
          .text {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .page-home {
    .client-say {
      .my-swiper {
        top: 126px;
        .swiper-item-inner {
          .text {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .page-home {
    .client-say {
      .my-swiper {
        top: 92px;
        .swiper-item-inner {
          .text {
            font-size: 12px;
            
          }
        }
      }
    }
  }
}


</style>

